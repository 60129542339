import {getInitialPagination} from "../../../../../utils/common-state";
import {
    CLEAR_FINANCIAL_MONITORING_FILTER,
    CLEAR_FINANCIAL_MONITORING_SNAPSHOTS,
    CLEAR_FINANCIAL_PROJECT_COLLABORATORS,
    CLEAR_FINANCIAL_PROJECT_COLLABORATORS_FILTER,
    CLEAR_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION,
    CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS,
    CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SORT,
    SET_FINANCIAL_PROJECT_COLLABORATORS,
    SET_FINANCIAL_PROJECT_COLLABORATORS_FILTER,
    SET_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION,
    SET_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS,
    SET_FINANCIAL_PROJECT_COLLABORATORS_SORT
} from "../../constants";

const initialState = {
    config: {
        pagination: getInitialPagination(false)
    },
    list: {
        sort: {
            col: { id: 0 },
            type: "asc"
        },
        filter: {
            search: "",
            periode: null
        },
        page: 0,
        size: getInitialPagination(false).size,
        collaborators: [],
        collaboratorsSnapshots:[]
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_PROJECT_COLLABORATORS:
            return setFinancialProjectCollaborators(state, action.payload);
        case SET_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION:
            return setFinancialProjectCollaboratorsPagination(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_COLLABORATORS:
            return clearFinancialProjectCollaborators(state);
        case CLEAR_FINANCIAL_PROJECT_COLLABORATORS_PAGINATION:
            return clearFinancialProjectCollaboratorsPagination(state);
        case SET_FINANCIAL_PROJECT_COLLABORATORS_SORT:
            return setCollaboratorsSort(state, action.payload);
        case CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SORT:
            return clearCollaboratorsSort(state);
        case SET_FINANCIAL_PROJECT_COLLABORATORS_FILTER:
            return setCollaboratorsFilter(state,action.payload);
        case CLEAR_FINANCIAL_PROJECT_COLLABORATORS_FILTER:
            return clearCollaboratorsFilter(state);
        case SET_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS:
            return setFinancialProjectCollaboratorsSnapshots(state,action.payload)
        case CLEAR_FINANCIAL_PROJECT_COLLABORATORS_SNAPSHOTS:
            return clearFinancialMonitoringSnapshotsByCollabortor(state)
        case CLEAR_FINANCIAL_MONITORING_SNAPSHOTS:
            return clearFinancialMonitoringSnapshots(state)
        case CLEAR_FINANCIAL_MONITORING_FILTER:
            return clearFinancialMonitoringFilter(state)
        default:
            return state;
    }
};

const setFinancialProjectCollaborators = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: {
                ...state.config.pagination,
                page: parseInt(payload.page),
                size: parseInt(payload.size),
                maxLength: parseInt(payload.maxLength)
            }
        },
        list: {
            ...state.list,
            page: parseInt(payload.page),
            size: parseInt(payload.size),
            collaborators: payload.fnMonitoring
        }
    };
};

const setFinancialProjectCollaboratorsPagination = (state, payload) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: payload
        }
    };
};

const clearFinancialProjectCollaborators = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            page: initialState.list.page,
            size: initialState.list.size,
            collaborators: initialState.list.collaborators
        }
    };
};

const clearFinancialProjectCollaboratorsPagination = (state) => {
    return {
        ...state,
        config: {
            ...state.config,
            pagination: getInitialPagination(false)
        }
    };
};

const setCollaboratorsSort = (state, sort) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: sort
        }
    };
};

const clearCollaboratorsSort = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.sort
        }
    };
};

const setCollaboratorsFilter = (state, filter) => {
    return {
        ...state,
        list: {
            ...state.list,
            filter
        }
    };
};
const setFinancialProjectCollaboratorsSnapshots = (state,collabSnapshots) => {
    return {
        ...state,
        list: {
            ...state.list,
            collaboratorsSnapshots:collabSnapshots
        }
    }
}


const clearCollaboratorsFilter = (state) => {
    return {
        ...state,
        list: {
            ...state.list,
            sort: initialState.list.filter
        }
    };
};

const clearFinancialMonitoringSnapshotsByCollabortor = (state) =>
{
    return {
        ...state,
        list: {
            ...state.list,
            collaboratorsSnapshots:initialState.list.collaboratorsSnapshots
        }
    }
}

const clearFinancialMonitoringSnapshots = (state) =>
{
    return {
        ...state,
        list: {
            ...state.list,
            collaborators:initialState.list.collaborators
        }
    }
}


const clearFinancialMonitoringFilter = (state) =>
{
    return {
        ...state,
        list: {
            ...state.list,
            filter:initialState.list.filter
        }
    }
}
