// NAMING CONVENTION: 
// + endpoints must follow RESTful naming principles:
//    + GET "/examples" to query list of examples
//    + GET "/examples/:id" to query one example by id
//    + POST "/examples" to create an example
//    + PUT "/examples/:id" to update an example by id
//    + DELETE "/examples/:id" to delete an example by id 
// + group endpoints by module/service/domain
// + all endpoints should mention 'FINANCIAL'
// + all endpoints should end with '_ENDPOINT'

/************* INDICATORS **************/
export const GLOBAL_FINANCIAL_INDICATORS_ENDPOINT = '/pilotage-bff/api/financial/indicators/global';
export const PROJECT_FINANCIAL_INDICATORS_ENDPOINT = '/pilotage-bff/api/financial/indicators/project';

/************* PROJECTS **************/
export const FINANCIAL_DASHBOARD_PROJECTS_ENDPOINT = '/pilotage-bff/api/financial/projects';

/************* COLLABORATORS **************/
export const FINANCIAL_PROJECT_COLLABORATORS_ENDPOINT = '/pilotage-bff/api/financial/projects/collaborators';

/************* COSTS **************/
export const FINANCIAL_COLLABORATOR_MONTHLY_COST_ENDPOINT = '/pilotage-bff/api/financial/costs/collaboratorMonthlyCost';
export const FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT = '/pilotage-bff/api/financial/costs/collaboratorCostHistory';
export const FINANCIAL_COLLABORATOR_TJM_HISTORY_ENDPOINT = '/pilotage-bff/api/financial/costs/collaboratorTjmHistory';

/************* SNAPSHOTS *************/
export const FINANCIAL_PROJECT_SNAPSHOTS_ENDPOINT = '/pilotage-bff/api/financial/projects/snapshots';

/************* INVOICES *************/
export const FINANCIAL_INVOICING_ENDPOINT = '/pilotage-bff/api/financial/invoicing';

/************* SALE_ORDERS *************/
export const FINANCIAL_PROJECT_SALE_ORDERS_ENDPOINT = '/pilotage-bff/api/financial/invoicingAndEchancier/saleOrders';

/************* ECHANCIER *************/
export const FINANCIAL_SALE_ORDER_ECHANCIER = '/pilotage-bff/api/financial/invoicingAndEchancier/echanciers';

/************* RT TABLES *************/
export const FINANCIAL_RT_ENDPOINT = '/pilotage-bff/api/financial/rt';