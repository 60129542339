import { errorNotification, successNotification } from "../../../../../utils/notification";
import { cloneDeep } from "lodash-es";
import isEmpty from 'lodash/isEmpty';
import {

    GET_FINANCIAL_COLLABORATOR_COST_HISTORY,

    POST_FINANCIAL_COLLABORATOR_COST_HISTORY,

    DELETE_FINANCIAL_COLLABORATOR_COST_HISTORY,

    PUT_FINANCIAL_COLLABORATOR_COST_HISTORY,

    SET_FINANCIAL_COLLABORATOR_COST_HISTORY,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER,

    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER,
    CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT,
    SET_FINANCIAL_COLLABORATOR_COST_HISTORY_BY_ID

} from "../../constants";

import {FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT} from "../../constants/endpoint";
import i18n from "../../../../../i18n";

const adjustFilter = (filter) => {
    let f = cloneDeep(filter);
    Object.keys(f).forEach(k => {
        if(isEmpty(f[k])){
            delete f[k];
        }else{
            switch(k){
                case 'collaborators':
                    f['collaboratorsIds'] = f[k].map(r => r.id).join(',');
                    delete f[k];
                    break;
                case 'costHistories':
                    f['costHistories'] = f[k].join(',');
                    break;
                default:
                    break;
            }
        }
    });

    return f;
};

export const getFinancialCollaboratorCostHistory = (id) => {
    return {
        type: GET_FINANCIAL_COLLABORATOR_COST_HISTORY,
        request: {
            url: FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                const state = store.getState();
                const pagination = state.financial.collaboratorCostHistory.config.pagination;
                const sort = state.financial.collaboratorCostHistory.list.sort;
                const filter = adjustFilter(state.financial.collaboratorCostHistory.list.filter);

                if(filter.search){
                    request.url = `${request.url}/search?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}`;
                    request.params = {
                        name: filter.search
                    };
                }else{
                    request.url = `${request.url}?sort=${(sort.col && sort.col.apiName) || "id"},${sort.type}&page=${pagination.page}&size=${pagination.size}&collaboratorIds=${id}`;
                    request.params = filter;
                }
                return request;
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setFinancialCollaboratorCostHistory(response.data));
                return response;
            }
        }
    };
};

export const getFinancialCollaboratorCostHistoryById = (id, current = false) => {
    return {
        type: GET_FINANCIAL_COLLABORATOR_COST_HISTORY,
        request: {
            url: `${FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT}/historyList/${id}`
        },
        meta: {
            onRequest: (request, requestAction, store) => {
                request.params = {
                    current
                };
                return request
            },
            onSuccess: (response, requestAction, store) => {
                store.dispatch(setFinancialCollaboratorCostHistoryById(response.data));
                return response;
            }
        }
    };
};

export const postFinancialCollaboratorCostHistory = (collaboratorCostHistory) => {
    return {
        type: POST_FINANCIAL_COLLABORATOR_COST_HISTORY,
        request: {
            url: FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT,
            method: 'post',
            data: collaboratorCostHistory
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "create_success");
                store.dispatch(getFinancialCollaboratorCostHistory());
                return response;
            },
            onError: (error) => {
                errorNotification('', "create_error");
                throw error;
            }
        }
    };
};

export const deleteFinancialCollaboratorCostHistory = (collaboratorCostHistoryId) => {
    return {
        type: DELETE_FINANCIAL_COLLABORATOR_COST_HISTORY,
        request: {
            url: `${FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT}/${collaboratorCostHistoryId}`,
            method: 'delete'
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "delete_success");
                store.dispatch(getFinancialCollaboratorCostHistory());
                return response;
            },
            onError: (error) => {
                errorNotification('', "delete_error");
                throw error;
            }
        }
    };
};

export const putFinancialCollaboratorCostHistory = (collabId, collaboratorCostHistoryList, alterProfileTjm) => {
    return {
        type: PUT_FINANCIAL_COLLABORATOR_COST_HISTORY,
        request: {
            url: `${FINANCIAL_COLLABORATOR_COST_HISTORY_ENDPOINT}/${collabId}?alterProfileTjm=${alterProfileTjm}`,
            method: 'put',
            data: collaboratorCostHistoryList
        },
        meta: {
            onSuccess: (response, requestAction, store) => {
                successNotification('', "update_success");
                store.dispatch(getFinancialCollaboratorCostHistoryById(collabId, true));
                return response;
            },
            onError: (error) => {
                const errorMessage = error.response.data
                errorNotification('', i18n.t(errorMessage.status === 400 ?  errorMessage.data : 'update_error'));
                throw error;

            }
        }
    };
};

export const setFinancialCollaboratorCostHistory = (data) => {
    return {
        type: SET_FINANCIAL_COLLABORATOR_COST_HISTORY,
        payload: data
    };
};

export const setFinancialCollaboratorCostHistoryById = (data) => {
    return {
        type: SET_FINANCIAL_COLLABORATOR_COST_HISTORY_BY_ID,
        payload: data
    };
};

export const setFinancialCollaboratorCostHistoryPagination = (pagination) => {
    return {
        type: SET_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION,
        payload: pagination
    };
};

export const clearFinancialCollaboratorCostHistory = () => {
    return {
        type: CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY
    };
};

export const clearFinancialCollaboratorCostHistoryPagination = () => {
    return {
        type: CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_PAGINATION
    };
};

export const setFinancialCollaboratorCostHistorySort = (sort) => {
    return {
        type: SET_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT,
        payload: sort
    };
};

export const clearFinancialCollaboratorCostHistorySort = () => {
    return {
        type: CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_SORT
    };
};

export const setFinancialCollaboratorCostHistoryFilter = (filter) => {
    return {
        type: SET_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER,
        payload: filter
    };
};

export const clearFinancialCollaboratorCostHistoryFilter = () => {
    return {
        type: CLEAR_FINANCIAL_COLLABORATOR_COST_HISTORY_FILTER
    };
};