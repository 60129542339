import { CLEAR_COLLABORATOR_TJM_HISTORY, SET_COLLABORATOR_TJM_HISTORY } from "../../constants";

const initialState = {
    collaboratorTjmHistory: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_COLLABORATOR_TJM_HISTORY:
            return setCollaboratorTjmHistory(state, action.payload);
        case CLEAR_COLLABORATOR_TJM_HISTORY:
            return clearCollaboratorTjmHistory(state, action.payload);
        default: return state;
    }
}


const setCollaboratorTjmHistory = (state, payload) => {
    return {
        ...state,
        collaboratorTjmHistory: payload
    };
};

const clearCollaboratorTjmHistory = (state, payload) => {
    return {
        ...state,
        collaboratorTjmHistory: initialState.collaboratorTjmHistory
    };
};